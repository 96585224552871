.invalid-input{
    color:#f50000;
    font-size: 12px;
    margin-left: auto !important;
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
  }

  .RowDiv {
    display: flex;
    width: 100%;
  }
  /* .ant-table-cell-row-hover {
    background-color: black;
  } */