.page{
    padding-left: 15px;
}
.page .m {
  display: -webkit-box;
  display: flex;
}
.page .m2 {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 4%;
}
.page .m2 .imgLogo {
    width: 80px;
  }
.page .m3 {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 5%;
}

.page .m3 .footer-right .imgLogo {
    width: 80px;
  }

.page .maps-contained {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 5%;
}
.bold-heading-left {
  margin-right: 30px;
}
.bold-heading-left h1 {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0 0 10px 0;
}
.bold-heading-left p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0;
}
.bold-heading-left h5 {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0;
  font-weight: 400;
}
.bold-heading-left span {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: rgb(94, 94, 94);
  margin-bottom: 0;
  letter-spacing: 0.05rem;
  font-weight: 400;
  text-align: right;
}
.bold-heading-left h2 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0;
  letter-spacing: 0.05rem;
  font-weight: 400;
}
.bold-heading-left pre {
  /* width: 50%; */
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0;
  font-weight: 400;
  /* overflow: hidden; */
  -ms-word-break: break-all;
  word-break: break-all;

  word-break: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.bold-heading-right h1 {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0 0 10px 0;
  text-align: right;
}
.bold-heading-right p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0;
  text-align: right;
}
.bold-heading-right span {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: rgb(94, 94, 94);
  margin-bottom: 0;
  letter-spacing: 0.05rem;
  font-weight: 400;
  text-align: right;
}
.page .location {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-top: 30px;
}
.locationQR {
  display: -webkit-box;
  display: flex;
}
.qrCode-contain {
  margin-top: -10px;
}
.qrCode {
  width: 100px;
}

.footer-left h1 {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0 0 10px 0;
}
.footer-left p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0;
}
.footer-right h1 {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0 0 10px 0;
  text-align: right;
}
.copia {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0 0 10px 0;
  font-weight: 500;
  -webkit-box-orient: vertical; /* WebKit */
  border: 2px solid rgb(49, 49, 49);
  border-radius: 10px;
  padding: 0 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
}
.mapsImages {
  width: 300px;
  height: 180px;
}

.actionBtnView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: fit-content; */
}

.actionBtnView .ant-btn {
  border-radius: 4px;
  /* width: fit-content; */
}

.actionBtn {
  cursor: pointer;
  border: none;
  width: 100%;
  height: 100%;
}

.actionBtn:hover {
  background-color: #F8F8F8;
}

/* .isoInvoiceTable .ant-table-cell {
  padding: 0;
} */

.cursor {
  cursor: pointer;
}