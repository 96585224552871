.image-container {
  width: 200px;
  height: 130px;
  border: 1px dashed #E9E9E9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.selected-image {
  max-width: 100%; /* Limit image width to the container's width */
  max-height: 100%; /* Limit image height to the container's height */
  width: auto;
  height: auto;
  object-fit: contain;
  cursor: pointer;
}

.camera-icon {
  font-size: 48px;
  color: #E9E9E9;
  cursor: pointer;
}
