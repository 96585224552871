.airportTransfer .imgLogo {
  width: 80px;
}
.airportTransfer .table-main {
  display: -webkit-box;
  display: flex;
  /* -webkit-box-pack: end;
    justify-content: flex-end; */
  margin-top: 30px;
}
.airportTransfer .heading {
  margin-top: 30px;
  margin-right: 30px;
}
.airportTransfer .heading h1 {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  color: rgb(49, 49, 49);
  margin: 0 0 10px 0;
}
.airportTransfer table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.airportTransfer table td,
.airportTransfer table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.airportTransfer table tr:nth-child(even) {
  background-color: #dddddd;
}
