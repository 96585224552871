.bookingFormWrapper {
    /* display: flex; */
  }

  .pink-row {
    background-color: rgb(254, 222, 227) !important;
  }

.rightbar {
    padding: 0 20px;
    border-left: 0.5px solid #E9E9E9;
    width: 20%;
  }

  .rightbar-inner {
    margin-top: 60px;
  }

  .rightbar-inner h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
    color: #788195;
  }

  .rightbar-inner p {
    padding: 8px;
    font-size: 14;
    font-weight: 300;
    line-height: 2;
    color: #788195;
    text-align: center;
  }

  .label-style {
    font-size: 13px;
    font-weight: 500;
    color: #7881A3;
    padding: 5px 5px;
  }

  
@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Initial scale */
  }
  50% {
    transform: scale(1.05); /* Scale up */
  }
  100% {
    transform: scale(1); /* Scale back to the original size */
  }
}

.developers {
  animation: scaleAnimation 1s infinite;
  width: fit-content;
}

.developers .link {
  color: #000000D9;
  font-family: 'Roboto',sans-serif;
  padding: 0 5px;
  
}